<template>
  <v-card class="transparent grey darken-3 rounded-xl ma-2" dark>
    <v-card-title>
      <v-icon class="mr-2">mdi-human-scooter</v-icon>
      <span>{{i.service}}</span>
    </v-card-title>
    <v-card-text>
      <v-divider class="mb-2"></v-divider>
      <div class="subtitle-2 subtitle">Visit restrictions</div>
      <WeekForTime :days="i.time.days" />
      <span class="mx-2">{{i.time.hours | time_interval}}</span>
      <div>
        <i18n path="validperiod">
          <template #from>{{i.fromdate | dt-only}}</template>
          <template #to>{{i.todate | dt-only}}</template>
        </i18n>
      </div>
      <div class="subtitle-2 subtitle mt-3">Service restrictions</div>
      <div v-if="i.visitcontrol">
        <i18n path="remainvisits">
          <template #count>
            <v-chip color="error" class="ml-2" dark small>{{i.remainvisits}}</v-chip>
          </template>
        </i18n>
      </div>
      <div v-if="i.scalar" class="text-right">
        <i18n path="remainscalar">
          <template #count>
            <v-chip outlined color="error" class="ml-2">{{i.remainscalar}}</v-chip>
          </template>
        </i18n>
      </div>
      <div v-if="i.timed">
        <i18n path="remaintime">
          <template #count>{{i.spendmin}}</template>
        </i18n>
      </div>
      <div v-if="i.workout">
        <v-chip outlined>
          <v-icon small left>mdi-weight-lifter</v-icon>
          <i18n path="isworkout" />
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ActiveServiceCard",
  props: ["service"],
  components: {
    WeekForTime: () => import("../components/WeekForTime.vue"),
  },
  computed: {
    i() {
      return this.service;
    },
  },
};
</script>

<style scoped>
.subtitle {
  text-decoration: underline;
}
</style>